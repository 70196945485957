<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部 -->
      <div class="head">
        <div class="left">
          <b class="left_tit">秒杀活动列表</b>
        </div>
        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div>
      <!-- 筛选部分 -->
      <div class="screen">
        <div class="screen_tit">
          <div class="screen_left">
            <i class="el-icon-search"></i>
            筛选查询
          </div>
          <div class="screen_right">
            <el-button @click="screen()" size="mini">查询结果</el-button>
          </div>
        </div>
        <div class="screen_cont">
          <el-form
            size="small"
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
          >
            <el-form-item label="优惠券名称：">
              <el-input
                v-model="coupon_name"
                placeholder="优惠券名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="优惠券类型:">
              <el-select v-model="coupon_type" placeholder="请选择优惠券类型">
                <el-option
                  v-for="item in couponType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择商户：" v-show="merchantShow">
              <el-select
                v-model="business_id"
                placeholder="请选择商户"
                @change="changeMerchant(business_id)"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.business_id"
                  :label="item.business_name"
                  :value="item.business_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择门店：" v-show="merchantShow">
              <el-select v-model="storeid" placeholder="请选择门店">
                <el-option
                  v-for="item in storeList"
                  :key="item.storeid"
                  :label="item.s_name"
                  :value="item.storeid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 表格头部 -->
      <div class="table_head">
        <div class="table_head_left">
          <i class="el-icon-s-unfold"></i>
          数据列表
        </div>
        <div class="table_head_right">
          <ul>
            <li>
              <el-button size="mini" @click="addActive"> 添加活动 </el-button>
            </li>
          </ul>

          <!-- 添加商品的弹出框 -->
          <div class="popContainer" v-show="goodsListShow">
            <transition name="el-fade-in">
              <div class="transition-box">
                <div class="act_tit">
                  编辑优惠券商品
                  <el-button
                    style="float: right; padding: 3px 10px"
                    type="text"
                    @click="closeAdd()"
                    >关闭按钮</el-button
                  >
                </div>
                <div style="margin-top: 5px; color: black">
                  <span>输入名称搜索：</span>
                  <el-input
                    v-model="searchIpt"
                    placeholder="请输入商品名称"
                    style="width: 200px"
                    size="small"
                  ></el-input>
                  <el-button
                    @click="searchBtn()"
                    style="padding: 3px 10px"
                    type="text"
                    >搜索按钮</el-button
                  >
                  <!-- 当前已选择{{ selectGoods }}件商品 -->
                  <el-button @click="addGoodsList" style="float: right"
                    >确认添加</el-button
                  >
                </div>

                <div class="table" style="margin-top: 2px; text-align: center">
                  <el-table
                    height="380px"
                    ref="multipleTable"
                    :data="addTableData"
                    tooltip-effect="dark"
                    @selection-change="handleSelectionChange"
                  >
                    <el-table-column
                      label="商品名称"
                      width="200"
                      align="center"
                    >
                      <template slot-scope="scope" align="center">{{
                        scope.row.gname
                      }}</template>
                    </el-table-column>
                    <el-table-column label="货号" width="180" align="center">
                      <template slot-scope="scope">{{
                        scope.row.gcode
                      }}</template>
                    </el-table-column>
                    <el-table-column
                      prop="original_price"
                      label="价格"
                      show-overflow-tooltip
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      type="selection"
                      lable="操作"
                      width="55"
                      align="center"
                    >
                    </el-table-column>
                  </el-table>
                  <el-pagination
                    style="margin-top: 15px"
                    :page-sizes="[10, 20, 30, 40]"
                    layout="total,  prev, pager, next, jumper"
                    :total="goodsListTotal"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </div>
            </transition>
          </div>

          <!-- 指定商品时的弹框 -->
          <div class="popContainer" v-show="selectGoodsListShow">
            <transition name="el-fade-in">
              <div class="transition-box">
                <div class="act_tit">
                  指定商品
                  <el-button
                    style="float: right; padding: 3px 10px"
                    type="text"
                    @click="closeAdd()"
                    >关闭按钮</el-button>
                </div>
                <div style="margin-top: 5px; color: black">
                 <!--  <span>输入名称搜索：</span>
                  <el-input
                    v-model="searchIpt"
                    placeholder="请输入商品名称"
                    style="width: 200px"
                    size="small"
                  ></el-input> -->
                  <!-- <el-button
                    @click="searchBtn()"
                    style="padding: 3px 10px"
                    type="text"
                    >搜索按钮</el-button
                  > -->
                  <!-- 当前已选择{{ selectGoods }}件商品 -->
                  <!-- <el-button @click="addGoodsList" style="float: right"
                    >确认添加</el-button
                  > -->
                </div>

                <div class="table" style="margin-top: 2px; text-align: center">
                  <el-table height="380px" :data="selectTableData" border>
                    <el-table-column
                      label="商品名称"
                      width="200"
                      align="center"
                    >
                      <template slot-scope="scope" align="center">{{
                        scope.row.gname
                      }}</template>
                    </el-table-column>
                    <el-table-column label="货号" width="180" align="center">
                      <template slot-scope="scope">{{
                        scope.row.gcode
                      }}</template>
                    </el-table-column>
                    <el-table-column label="商品id" width="100" align="center">
                      <template slot-scope="scope">{{
                        scope.row.coupon_goods_id
                      }}</template>
                    </el-table-column>
                    <el-table-column
                      prop="original_price"
                      label="价格"
                      align="center"
                    >
                    </el-table-column>

                    <el-table-column prop="gname" label="操作" align="center">
                      
                        <template slot-scope="scope">
             <span style="margin-left: 10px; cursor: pointer" @click="delSelect( scope.row.coupon_goods_id)">
                        删除</span>
            </template>
                    </el-table-column>

                  </el-table>
                  <el-pagination
                    style="margin-top: 15px"
                    :page-sizes="[10, 20, 30, 40]"
                    layout="total,  prev, pager, next, jumper"
                    :total="goodsListTotal"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <!-- 表格渲染 -->
      <div class="table">
        <!-- 设置表格高度  height="500" -->
        <el-table
          :data="tableData"
          border
          style="width: 85%; margin-left: 200px; text-align: center"
        >
          <el-table-column prop="" label="编号" align="center">
          </el-table-column>
          <el-table-column prop="coupon_name" label="优惠券名称" align="center">
          </el-table-column>

          <el-table-column prop="business_name" label="适用商户" align="center">
          </el-table-column>
          <el-table-column prop="store_name" label="适用门店" align="center">
          </el-table-column>
          <el-table-column prop="goods_type" label="可使用商品" align="center">
            <template slot-scope="scope">
              <span v-show="scope.row.goods_type == 1 ? true : false"
                >全部商品</span
              >
              <span
                @click="gList(scope.row.coupon_id)"
                v-show="scope.row.goods_type == 2 ? true : false"
                style="cursor: pointer; color: red"
                >指定商品</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="limit" label="使用门槛" align="center">
          </el-table-column>
          <el-table-column label="面值" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.discount_price }}元</span>
            </template>
          </el-table-column>
          <el-table-column prop="effective_time" label="有效期" align="center">
          </el-table-column>
          <el-table-column prop="expire" label="状态" align="center">
          </el-table-column>

          <el-table-column
            prop="operation"
            label="操作"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <!-- <span style="cursor: pointer" @click="see()">查看</span> -->
              <span
                style="margin-left: 10px; cursor: pointer"
                v-show="scope.row.goods_type == 2 ? true : false"
                @click="
                  edit(
                    scope.row.coupon_id,
                    scope.row.goods_type,
                    scope.row.business_id,
                    scope.row.storeid
                  )
                "
              >
                编辑
              </span>
              <span
                v-show="storeShow"
                style="margin-left: 10px; cursor: pointer"
                @click="deleteCoupon(scope.row.coupon_id)"
              >
                删除</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="activeListTotal"
        >
        </el-pagination>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../../public/Frame.vue";

export default {
  data() {
    return {
      allGoods: false,
      goods: false,
      top1: "7-3",
      top2: ["7"],
      flag: 1,
      admin_type: "",
      //下拉框内容
      formInline: {
        user: "",
        region: "",
        num: "",
        order: "",
      },
      coupon_name: "", //活动名称搜索的input框的值
      active_status: "", //选择活动状态
      merchant_status: "", //选择商户
      merchantShow: false, //商户个别权限的隐藏
      storeShow: false, //门店权限的隐藏
      show: false,
      selectGoodsListShow: false, //指定商品的显示隐藏
      goodsListShow: false,
      businessList: [{}], //商户列表
      business_id: "", //选中的商户ID
      storeList: [{}], //门店列表
      storeid: "", //选中的门店ID
      couponType: [
        {
          value: "1",
          label: "注册赠券",
        },
        {
          value: "2",
          label: "购物赠券",
        },
        {
          value: "3",
          label: "全场赠券",
        },
        {
          value: "4",
          label: "会员赠券",
        },
      ],
      coupon_type: "",
      //添加活动
      sizeForm: {
        name: "",
        merchant: "",
        date1: "",
        date2: "",
        endDate1: "",
        endDate2: "",
        type: [],
        resource: "",
      },
      //修改活动
      sizeForm2: {
        active_id: "",
        active_title: "",
      },
      cur_page: 0, //设置一个默认值
      addTableData: [], //优惠券商品列表
      goodsListTotal: 10, //优惠商品总条数
      tableData: [], //表格渲染数据
      coupon_id: "", //编辑时的优惠券id
      activeListTotal: 10, //表格总条数
      searchIpt: "", //优惠商品列表搜索值
      dataSelections: "", //选中的商品列表数据
      selectTableData: [], //指定商品的商品数据
      goods_type: "", //指定商品还是全部商品类型
      //总页数
      currentPage: 1,
      pageSize: 10,
      value2: true,
    };
  },
  methods: {
    //选中商户查询门店
    changeMerchant(business_id) {
      console.log(business_id);
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: business_id,
        })
        .then((res) => {
          console.log(res);
          this.storeList = res.data;
        });
    },
    //查询按钮
    screen() {
      console.log(this.coupon_name);
      this.$request
        .couponList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          coupon_name: this.coupon_name,
          type: this.coupon_type,
          business_id: this.business_id,
          store_id: this.storeid,
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data;
          this.activeListTotal = res.data.count;
        });
    },

    //刷新页面按钮
    refresh() {
      location.reload();
    },
    //添加活动按钮(跳转到添加优惠券)
    addActive() {
      this.$router.push({ path: "/addCouponInfo" });
    },
    // 监听选择商户变化
    currStationChange() {
      this.$request
        .businessStoreList({
          business_id: this.business_id,
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
        })
        .then((res) => {
          this.storeList = res.data;
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = parseInt(`${val}`);
      this.$request
        .couponList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          coupon_name: "",
          type: "", //类型1：注册赠券:2：购物赠券3：全场赠券4:会员赠券
          business_id: "",
          store_id: "",
          page: this.currentPage,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data;
          this.activeListTotal = res.data.count;
        });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = parseInt(`${val}`);
      console.log(1111111111111111111, this.currentPage);
      this.$request
        .couponList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          coupon_name: "",
          type: "", //类型1：注册赠券:2：购物赠券3：全场赠券4:会员赠券
          business_id: "",
          store_id: "",
          page: this.currentPage,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data;
          this.activeListTotal = res.data.count;
        });
    },

    //查看商品
    // see() {
    //   alert("点击了查看商品");
    // },

    //编辑商品按钮
    edit(coupon_id, goods_type, business_id, storeid) {
      console.log("编辑商品的默认值", coupon_id, goods_type);
      this.coupon_id = coupon_id;
      this.goodsListShow = true;
      this.goods_type = goods_type;
      this.business_id = business_id;
      this.$request
        .couponShop({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          coupon_id: coupon_id,
          goods_type: goods_type,
          search: "",
          business_id: business_id,
          store_id: "",
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          this.addTableData = res.data.data;
          this.goodsListTotal = res.data.count;
        });
    },
    //优惠券商品列表选择
    handleSelectionChange(val) {
      console.log("选中了的数据", val);
      this.dataSelections = val;
      console.log(val.length);
      this.selectGoods = val.length;
    },
    //确认添加
    addGoodsList() {
      let val = this.dataSelections;
      for (var i = 0; i < val.length; i++) {
        val[i].active_id = this.active_id;
        // val[i].type = 1;
        delete val[i].gname;
        delete val[i].gcode;
        delete val[i].original_price;
        delete val[i].coupon_goods_id;
      }
      console.log(val);
      this.dataSelections = JSON.stringify(val);
      console.log(this.dataSelections);
      // return false
      this.$request
        .updateCoupon({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          coupon_id: this.coupon_id,
          goods_data: this.dataSelections,
        })
        .then((res) => {
          this.goodsListShow = false;
          console.log(res);
          // return false
          this.$request
            .couponList({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              coupon_name: "",
              type: "", //类型1：注册赠券:2：购物赠券3：全场赠券4:会员赠券
              business_id: "",
              store_id: "",
              page: this.currentPage,
              limit: this.pageSize,
            })
            .then((res) => {
              console.log(res);
              this.tableData = res.data.data;
              this.activeListTotal = res.data.count;
            });
        });
    },
    //优惠券商品列表搜索按钮
    searchBtn() {
      console.log(this.searchIpt);
      console.log("优惠券商品列表搜索按钮");
      this.$request
        .couponShop({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          coupon_id: this.coupon_id,
          goods_type: this.goods_type,
          search: this.searchIpt,
          business_id: this.business_id,
          store_id: "",
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          this.addTableData = res.data.data;
          this.goodsListTotal = res.data.count;
        });
    },
    //关闭添加商品窗口
    closeAdd() {
      this.goodsListShow = false;
      this.selectGoodsListShow = false;
    },
    //指定商品按钮，已经添加的商品
    gList(coupon_id) {
      console.log("点击指定商品");
      this.coupon_id=coupon_id
      this.selectGoodsListShow = true;
      this.$request
        .couponShopList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          // admin_type: this.$storage.getLocal("admin_type"), //身份
          coupon_id: coupon_id,
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log("指定商品的数据", res);
          this.selectTableData = res.data.data;
          this.activeListTotal = res.data.count;
        });
    },

    //删除指定商品列表的商品
    delSelect(coupon_goods_id) {
      console.log(coupon_goods_id);
       this.$request
        .deleteCouponGoods({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          // admin_type: this.$storage.getLocal("admin_type"), //身份
          coupon_goods_id:coupon_goods_id,
        })
        .then((res) => {
          this.$request
        .couponShopList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          // admin_type: this.$storage.getLocal("admin_type"), //身份
          coupon_id: this.coupon_id,
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log("指定商品的数据", res);
          this.selectTableData = res.data.data;
          this.activeListTotal = res.data.count;
        });
        });
    },

    //删除优惠券
    deleteCoupon(coupon_id) {
      console.log(coupon_id);
      console.log(typeof coupon_id);
      this.$request
        .deleteCoupon({
          token: this.$storage.getLocal("token"),
          coupon_id: coupon_id,
        })
        .then((res) => {
          console.log(res);
          this.$request
            .couponList({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              coupon_name: "",
              type: "", //类型1：注册赠券:2：购物赠券3：全场赠券4:会员赠券
              business_id: "",
              store_id: "",
              page: 1,
              limit: this.pageSize,
            })
            .then((res) => {
              console.log(res);
              this.tableData = res.data.data;
              this.activeListTotal = res.data.count;
            });
        });
    },
    //优惠券类型
    changeStatus(merchant_status) {},
    merchantStatus(active_status) {
      console.log(active_status);
      this.$request
        .activeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          status: this.active_status, //活动状态
          type: 1, //1是秒杀，2是团购
          active_title: "",
          business_id: "",
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.activeListTotal = res.data.count;
          // }
        });
    },
  },

  created() {
    this.$request.businessList({}).then((res) => {
      console.log(res);
      this.businessList = res.data;
    });
    this.admin_type = this.$storage.getLocal("type");
    console.log("business_id", this.business_id);
    // let business_id = this.business_id;
    if (this.admin_type == 1) {
      this.merchantShow = true;
      this.storeShow = true;
    }
    if (this.admin_type == 2) {
      this.storeShow = true;
    }

    this.$request
      .couponList({
        token: this.$storage.getLocal("token"), //登陆进来的tooken
        admin_type: this.$storage.getLocal("type"), //身份
        coupon_name: "",
        type: "", //类型1：注册赠券:2：购物赠券3：全场赠券4:会员赠券
        business_id: "",
        store_id: "",
        page: this.currentPage,
        limit: this.pageSize,
      })
      .then((res) => {
        console.log(res);
        this.tableData = res.data.data;
        this.activeListTotal = res.data.count;
      });
  },
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 90px;
}
.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}
.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}
.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}
.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
}
.transition-box {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 580px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}
.act_tit {
  width: 800px;
  height: 50px;
  background-color: rgb(236, 128, 141);
  margin-top: -40px;
  margin-left: -20px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}
.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}
.block {
  margin-top: 30px;
}
</style>